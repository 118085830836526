<template>
  <div>
    <Navbar />
    <div class="page-wrapper">
      <Loader v-if="responseData == null" />
      <div v-else>
        <div v-if="tokenExisitng" class="page-content">
          <div class="content-title">Accept Invite from {{ inviter.name }}</div>
          <div class="page-message">
            You were invited by {{ inviter.name }} as a collaborator on
            <span v-if="project && project.project_name">{{
              project.project_name
            }}</span>
            . Kindly fill your details to continue.
          </div>
          <div class="grouped-form">
            <div class="form-label">Full Name</div>
            <input
              class="form-control"
              name="full_name"
              placeholder="Enter full name"
              id="project_name"
              type="text"
              v-model="form.name"
            />
          </div>
          <div class="grouped-form">
            <div class="form-label">Email Address</div>
            <input
              v-model="collaborator.email"
              class="form-control"
              name="email"
              placeholder="Email Address"
              id="email"
              type="text"
              disabled
            />
          </div>
          <div class="grouped-form">
            <div class="form-label">Role</div>
            <input
              class="form-control"
              name="role"
              placeholder="What’s your role? e.g. COO"
              id="project_name"
              type="text"
              v-model="form.role"
            />
          </div>
          <div class="grouped-form">
            <div class="form-label">Password</div>
            <div class="pass-field">
              <input
                class="form-control"
                name="password"
                v-model="form.password"
                placeholder="Choose a password"
                id="password"
                @keyup="validatePassword()"
                :type="!ispwdVisible ? 'password' : 'text'"
              />
              <img
                :src="
                  !ispwdVisible
                    ? '/img/onboarding/eye_close.svg'
                    : '/img/onboarding/eye_open.svg'
                "
                alt="eye"
                style="width: 5%"
                @click="() => (ispwdVisible = !ispwdVisible)"
              />
            </div>
          </div>
          <div class="service-agreement">
            By continuing you agree to our
            <span @click="termsModal = true">Terms of Service</span> and
            <span @click="privacyModal = true">Privacy Policy</span>
          </div>
          <div class="action-buttons">
            <button @click="handleProceed" class="grupa-white-btn">
              Reject Invite
            </button>
            <button class="grupa-blue-btn" @click="acceptCollaboratorFunc">
              Accept Invite
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
        </div>
        <div v-else class="page-content">
          <div class="page-message">
            <h2>Invalid Invite Link.</h2>
          </div>
        </div>
      </div>
    </div>

    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />

    <transition name="modal-fade">
      <TermsOfService v-if="termsModal" @close="closeTermsModal" />
    </transition>
    <transition name="modal-fade">
      <PrivacyPolicy v-if="privacyModal" @close="closePrivacyModal" />
    </transition>
  </div>
</template>

<script>
import Axios from "axios";
import { inviteCollaborator } from "@/api/client";
import Navbar from "@/components/Navbar/onboarding_navbar";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import { mapActions, mapState } from "vuex";
import TermsOfService from "@/components/general/termsOfService";
import PrivacyPolicy from "@/components/general/privacyPolicy";
import {
  checkCollaboratorInviteLink,
  acceptCollaborator,
} from "@/api/onboarding";
import Loader from "@/components/general/centerLoader";

export default {
  props: {},
  components: {
    Navbar,
    AlertSuccess,
    AlertError,
    TermsOfService,
    PrivacyPolicy,
    Loader,
  },

  data: () => ({
    collaborators: [],
    spinner: false,
    ispwdVisible: false,
    form: {
      email: "",
      full_name: "",
      password: "",
      phone_number: "",
      countryCode: "",
      role: "",
    },
    termsModal: false,
    privacyModal: false,
    tokenExisitng: false,
    responseData: null,
    collaborator: {},
    inviter: {},
    project: {},
  }),

  created() {
    this.checkToken();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("userManagement", ["setAutheticatedUser", "setUserLocation"]),

    handleComma(event) {
      if (event.key == "," || event.key == " ") {
        event.preventDefault();
        let el = document.getElementById("collaborators");
        var e = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          char: "Tab",
          key: "Tab",
          shiftKey: true,
          keyCode: 9,
        });
        el.dispatchEvent(e);
      } else {
        event;
      }
    },

    handleProceed() {
      let projectId = this.$route.params.id;
      this.$router.push({
        name: "project_workspace",
        params: { id: projectId },
      });
    },

    validatePassword() {
      const isUpperCaseExist = /([A-Z])/g.test(this.form.password);
      const isNumberExist = /\d/.test(this.form.password, 10);
      let field = document.getElementById("password");
      if (
        this.form.password.length < 8 ||
        !isUpperCaseExist ||
        !isNumberExist
      ) {
        this.fieldError = false;
      }
      if (this.form.password.length >= 8 && isUpperCaseExist && isNumberExist) {
        this.fieldError = true;
      }
      if (isUpperCaseExist) {
        this.isUpper = false;
      }
      if (!isUpperCaseExist) {
        this.isUpper = true;
      }
      if (isNumberExist) {
        this.isNum = false;
      }
      if (!isNumberExist) {
        this.isNum = true;
      }
      if (this.form.password.length >= 8) {
        this.isLength = false;
      }
      if (this.form.password.length < 8) {
        this.isLength = true;
      }
    },
    async checkToken() {
      let token = this.$route.params.token;
      await Axios.get(
        this.$baseUrl + `/onboarding/client/collaborator/${token}`
      )
        .then((response) => {
          this.inviter = response.data.inviter;
          this.collaborator = response.data.collaborator;
          this.project = response.data.project;
          this.responseData = {};
          this.tokenExisitng = true;
        })
        .catch((error) => {
          this.responseData = {};
        });
    },
    acceptCollaboratorFunc() {
      this.spinner = true;
      let token = this.$route.params.token;
      if (!this.form.role && !this.form.name && !this.form.password) {
        return;
      }
      let payload = {
        role: this.form.role,
        name: this.form.name,
        password: this.form.password,
      };

      Axios.post(
        this.$baseUrl + `/onboarding/client/collaborator/${token}`,
        payload
      )
        .then((response) => {
          this.setLogInUser(response.data.user);
          this.spinner = false;

          this.$router.push({
            name: "project_workspace",
            params: { id: this.project.id },
          });
        })
        .catch((error) => {});
    },
    closeTermsModal() {
      this.termsModal = false;
    },
    closePrivacyModal() {
      this.privacyModal = false;
    },
    setLogInUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
      localStorage.setItem("grupa_user", JSON.stringify(user));
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.page-content {
  width: 410px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.content-title {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: left;
}
.page-message {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 16px;
}
.form-label {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
  margin: 48px 0px 4px 0px;
}
.text-box-hint {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}
.action-buttons {
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
}
.grupa-white-btn {
  margin-right: 16px;
}
</style>
<style>
.grouped-form .v-text-field {
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 130%;
}
.pass-field {
  position: relative;
  height: 50px;
}
.pass-field img {
  position: absolute;
  top: 30%;
  right: 12px;
  cursor: pointer;
}
.service-agreement {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.already-login span,
.service-agreement span {
  color: #0781f2;
  cursor: pointer;
}
</style>
