import Axios from "axios";


//let baseURL = 'https://grupa-api-pre-live.herokuapp.com/api';
let baseURL = process.env.VUE_APP_BACKEND_API;

const user = JSON.parse(localStorage.getItem("grupa_user"));

const axios = Axios.create({
  baseURL,
  timeout: 100000,
});
// axios.defaults.headers.common
axios.interceptors.request.use(
  function(config) {
    const user = JSON.parse(localStorage.getItem("grupa_user"));
    config.headers.Authorization = `Bearer ${user.token}`;
    config.headers.common["Access-Control-Allow-Origin"] = "*";
    config.headers.common["Content-Type"] = `application/json`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

//Select team for project
export const updateOnboardingProject = (projectId, payload) => {
    return axios.post(
      `${baseURL}/onboarding/client/project/update/${projectId}`,
      payload
    );
};

// Check collaborator invite link
export const checkCollaboratorInviteLink = (token) => {
  return axios.get(
    `${baseURL}/onboarding/client/collaborator/${token}`
  );
};

export const acceptCollaborator = (token, payload) => {
  return axios.post(
    `${baseURL}/onboarding/client/collaborator/${token}`, payload
  );
};





//