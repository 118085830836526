var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "page-wrapper" },
        [
          _vm.responseData == null
            ? _c("Loader")
            : _c("div", [
                _vm.tokenExisitng
                  ? _c("div", { staticClass: "page-content" }, [
                      _c("div", { staticClass: "content-title" }, [
                        _vm._v(
                          "Accept Invite from " + _vm._s(_vm.inviter.name)
                        ),
                      ]),
                      _c("div", { staticClass: "page-message" }, [
                        _vm._v(
                          "\n          You were invited by " +
                            _vm._s(_vm.inviter.name) +
                            " as a collaborator on\n          "
                        ),
                        _vm.project && _vm.project.project_name
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.project.project_name)),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n          . Kindly fill your details to continue.\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "grouped-form" }, [
                        _c("div", { staticClass: "form-label" }, [
                          _vm._v("Full Name"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "full_name",
                            placeholder: "Enter full name",
                            id: "project_name",
                            type: "text",
                          },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "grouped-form" }, [
                        _c("div", { staticClass: "form-label" }, [
                          _vm._v("Email Address"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.collaborator.email,
                              expression: "collaborator.email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "email",
                            placeholder: "Email Address",
                            id: "email",
                            type: "text",
                            disabled: "",
                          },
                          domProps: { value: _vm.collaborator.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.collaborator,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "grouped-form" }, [
                        _c("div", { staticClass: "form-label" }, [
                          _vm._v("Role"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.role,
                              expression: "form.role",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "role",
                            placeholder: "What’s your role? e.g. COO",
                            id: "project_name",
                            type: "text",
                          },
                          domProps: { value: _vm.form.role },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "role", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "grouped-form" }, [
                        _c("div", { staticClass: "form-label" }, [
                          _vm._v("Password"),
                        ]),
                        _c("div", { staticClass: "pass-field" }, [
                          (!_vm.ispwdVisible ? "password" : "text") ===
                          "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "password",
                                  placeholder: "Choose a password",
                                  id: "password",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.form.password)
                                    ? _vm._i(_vm.form.password, null) > -1
                                    : _vm.form.password,
                                },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.validatePassword()
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.form.password,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "password",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "password",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "password", $$c)
                                    }
                                  },
                                },
                              })
                            : (!_vm.ispwdVisible ? "password" : "text") ===
                              "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "password",
                                  placeholder: "Choose a password",
                                  id: "password",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.form.password, null),
                                },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.validatePassword()
                                  },
                                  change: function ($event) {
                                    return _vm.$set(_vm.form, "password", null)
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "password",
                                  placeholder: "Choose a password",
                                  id: "password",
                                  type: !_vm.ispwdVisible ? "password" : "text",
                                },
                                domProps: { value: _vm.form.password },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.validatePassword()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                          _c("img", {
                            staticStyle: { width: "5%" },
                            attrs: {
                              src: !_vm.ispwdVisible
                                ? "/img/onboarding/eye_close.svg"
                                : "/img/onboarding/eye_open.svg",
                              alt: "eye",
                            },
                            on: {
                              click: function () {
                                return (_vm.ispwdVisible = !_vm.ispwdVisible)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "service-agreement" }, [
                        _vm._v(
                          "\n          By continuing you agree to our\n          "
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.termsModal = true
                              },
                            },
                          },
                          [_vm._v("Terms of Service")]
                        ),
                        _vm._v(" and\n          "),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.privacyModal = true
                              },
                            },
                          },
                          [_vm._v("Privacy Policy")]
                        ),
                      ]),
                      _c("div", { staticClass: "action-buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "grupa-white-btn",
                            on: { click: _vm.handleProceed },
                          },
                          [_vm._v("\n            Reject Invite\n          ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "grupa-blue-btn",
                            on: { click: _vm.acceptCollaboratorFunc },
                          },
                          [
                            _vm._v("\n            Accept Invite\n            "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.spinner,
                                  expression: "spinner",
                                },
                              ],
                              staticClass: "button-spinner",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { src: "/img/lightbox/preloader.gif" },
                            }),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "page-content" }, [
                      _c("div", { staticClass: "page-message" }, [
                        _c("h2", [_vm._v("Invalid Invite Link.")]),
                      ]),
                    ]),
              ]),
        ],
        1
      ),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.termsModal
            ? _c("TermsOfService", { on: { close: _vm.closeTermsModal } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.privacyModal
            ? _c("PrivacyPolicy", { on: { close: _vm.closePrivacyModal } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }