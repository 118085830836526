var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "terms_modal_backdrop" }, [
    _c(
      "div",
      { staticClass: "terms_modal", staticStyle: { "text-align": "left" } },
      [
        _c("div", { staticClass: "modal-title" }, [
          _c("span"),
          _vm._v(" Grupa Inc. Terms of Service\n            "),
          _c("img", {
            staticStyle: {
              float: "right",
              cursor: "pointer",
              "margin-top": "10px",
            },
            attrs: { src: "/img/dashboard/dev/close.svg" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ]),
        _c("h3", [_vm._v("1. Terms")]),
        _vm._m(0),
        _c("h3", [_vm._v("2. Use License")]),
        _vm._m(1),
        _c("h3", [_vm._v("3. Disclaimer")]),
        _vm._m(2),
        _c("h3", [_vm._v("4. Limitations")]),
        _c("p", [
          _vm._v(
            "In no event shall Grupa Inc. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Grupa Inc.'s website, even if Grupa Inc. or a Grupa Inc. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you."
          ),
        ]),
        _c("h3", [_vm._v("5. Accuracy of materials")]),
        _c("p", [
          _vm._v(
            "The materials appearing on Grupa Inc.'s website could include technical, typographical, or photographic errors. Grupa Inc. does not warrant that any of the materials on its website are accurate, complete or current. Grupa Inc. may make changes to the materials contained on its website at any time without notice. However Grupa Inc. does not make any commitment to update the materials."
          ),
        ]),
        _c("h3", [_vm._v("6. Links")]),
        _c("p", [
          _vm._v(
            "Grupa Inc. has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Grupa Inc. of the site. Use of any such linked website is at the user's own risk."
          ),
        ]),
        _c("h3", [_vm._v("7. Modifications")]),
        _c("p", [
          _vm._v(
            "Grupa Inc. may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service."
          ),
        ]),
        _c("h3", [_vm._v("8. Governing Law")]),
        _c("p", [
          _vm._v(
            "These terms and conditions are governed by and construed in accordance with the laws of Delaware and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location."
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("By accessing the website at "),
      _c("a", { attrs: { href: "http://grupa.io" } }, [
        _vm._v("http://grupa.io"),
      ]),
      _vm._v(
        ", you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { attrs: { type: "a" } }, [
      _c("li", [
        _vm._v(
          "Permission is granted to temporarily download one copy of the materials (information or software) on Grupa Inc.'s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:\n        "
        ),
        _c("ol", { attrs: { type: "i" } }, [
          _c("li", [_vm._v("modify or copy the materials;")]),
          _c("li", [
            _vm._v(
              "use the materials for any commercial purpose, or for any public display (commercial or non-commercial);"
            ),
          ]),
          _c("li", [
            _vm._v(
              "attempt to decompile or reverse engineer any software contained on Grupa Inc.'s website;"
            ),
          ]),
          _c("li", [
            _vm._v(
              "remove any copyright or other proprietary notations from the materials; or"
            ),
          ]),
          _c("li", [
            _vm._v(
              'transfer the materials to another person or "mirror" the materials on any other server.'
            ),
          ]),
        ]),
      ]),
      _c("li", [
        _vm._v(
          "This license shall automatically terminate if you violate any of these restrictions and may be terminated by Grupa Inc. at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { attrs: { type: "a" } }, [
      _c("li", [
        _vm._v(
          "The materials on Grupa Inc.'s website are provided on an 'as is' basis. Grupa Inc. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights."
        ),
      ]),
      _c("li", [
        _vm._v(
          "Further, Grupa Inc. does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }